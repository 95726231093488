<template>
  <div id="page_container_none" class="fullscreen bg-white q-pa-md flex canna_page404" style="flex-direction: column; flex-wrap: nowrap !important;">
    <div class="noselect">
      <div class="header_title">Canna</div>
    </div>
    <div class="flex text-center noselect" style="flex: 1;flex-direction: column;"
      :style="(pageSize === 'xs' || pageSize === 'sm') ? 'margin-top:54px': 'margin-top:84px'"
    >
      <div>
        <div class="main_title">
          Parece que <template v-if="(pageSize === 'xs')"><br></template> lograste perderte
        </div>
        <div class="main_subtitle" :style="(pageSize === 'xs' || pageSize === 'sm') ? 'margin-top:8px': 'margin-top:32px'">
          ¡No hay problema! <template v-if="(pageSize === 'xs')"><br></template> Navega de vuelta a la página de inicio
        </div>
      </div>
      <div :style="(pageSize === 'xs' || pageSize === 'sm') ? 'margin-top:40px': 'margin-top:64px'">
        <q-btn color="app_mainBtn" 
          :size="'md'"
          label="Llévame a la página de inicio" 
          no-caps unelevated 
          @click.stop="go_HomePage($event)"
          style="width:281px;"
        />
        <!--   (pageSize === 'xs' || pageSize === 'sm') ? 'sm' : 'md'  -->
      </div>
      <div class="" :style="(pageSize === 'xs' || pageSize === 'sm') ? 'margin-top:40px': 'margin-top:64px'">
        <div>
          <cxBackground :bgType="'app_404'" :size="currentSizeNodata" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import myplatform_Mixin from './../mixins/platformMix.js'
export default {
  name: 'Error404',
  mixins: [
    myplatform_Mixin
  ],
  components:{
    cxBackground: require('components/appBackgrounds').default
  },
  data () {
    return {
      currentSizeNodata : '327px',
    }
  },
  methods:{
    go_HomePage () {
      console.log("go home")
      window.location = '/'
    }
  }
}
</script>
